import React, { PureComponent } from 'react';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';
import { GlobalContext } from '../App/GlobalContext';

class WelcomeSegment extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Container text={true} style={{ marginBottom: '1rem' }}>
            <Segment textAlign="center" size="large" inverted={darkMode}>
              <Header>GDQ VODs</Header>
              <p>
                GDQ VODs allows you to search and browse through all the speedruns that have been played at an AGDQ or
                SGDQ marathon. Below is a hand picked selection of some highlights over the years, but you can use the
                top menu to find videos from a specific event, platform, genre, or category, and you can use the search
                box to find a particular game or runner.
              </p>
              <p style={{ marginBottom: '0.5rem' }}>Games Done Quick can be found at</p>
              <div style={{ marginBottom: '1rem' }}>
                <a
                  href="http://gamesdonequick.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Games Done Quick website"
                >
                  <Icon name="home" size="big" circular={true} link={true} inverted={darkMode} />
                </a>{' '}
                <a
                  href="http://www.twitch.tv/GamesDoneQuick"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Games Done Quick on Twitch"
                >
                  <Icon name="twitch" size="big" circular={true} link={true} inverted={darkMode} />
                </a>{' '}
                <a
                  href="https://bsky.app/profile/gamesdonequick.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Games Done Quick on Bluesky"
                >
                  <Icon name="cloud" size="big" circular={true} link={true} inverted={darkMode} />
                </a>{' '}
                <a
                  href="http://twitter.com/GamesDoneQuick"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Games Done Quick on Twitter"
                >
                  <Icon name="twitter" size="big" circular={true} link={true} inverted={darkMode} />
                </a>{' '}
                <a
                  href="http://www.youtube.com/user/gamesdonequick"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Games Done Quick on YouTube"
                >
                  <Icon name="youtube" size="big" circular={true} link={true} inverted={darkMode} />
                </a>{' '}
                <a
                  href="http://www.facebook.com/GamesDoneQuick/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Games Done Quick on Facebook"
                >
                  <Icon name="facebook" size="big" circular={true} link={true} inverted={darkMode} />
                </a>
              </div>
              <p>
                If you have any comments or suggestions for this site, send an email to{' '}
                <a href="mailto:email.gdqvods@gmail.com">email.gdqvods@gmail.com</a>.
              </p>
            </Segment>
          </Container>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default WelcomeSegment;
export { WelcomeSegment };
